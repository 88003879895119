<template>
  <vnud-card>
    <template v-slot:header>
      <h5 class="title">Edit Profile</h5>
    </template>
    <form>
      <div class="row">
        <div class="col-md-3">
          <fg-input type="text" :disabled="true" label="Username" placeholder="Username" v-model="user.username">
          </fg-input>
        </div>
        <div class="col-md-5">
          <fg-input type="text" label="Company" placeholder="Paper dashboard" v-model="user.company"
            @blur="updateUserField('company', $event.target.value)"
            @keydown.enter="updateUserField('company', $event.target.value)">
          </fg-input>
        </div>

        <div class="col-md-4">
          <fg-input type="email" label="Email" placeholder="Email" v-model="user.email"
            @blur="updateUserField('email', $event.target.value)"
            @keydown.enter="updateUserField('email', $event.target.value)">
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <fg-input type="text" label="First Name" placeholder="First Name" v-model="user.first_name"
            @blur="updateUserField('first_name', $event.target.value)"
            @keydown.enter="updateUserField('first_name', $event.target.value)">
          </fg-input>
        </div>
        <div class="col-md-6">
          <fg-input type="text" label="Last Name" placeholder="Last Name" v-model="user.last_name"
            @blur="updateUserField('last_name', $event.target.value)"
            @keydown.enter="updateUserField('last_name', $event.target.value)">
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <fg-input type="text" label="Address" placeholder="Home Address" v-model="user.address"
            @blur="updateUserField('address', $event.target.value)"
            @keydown.enter="updateUserField('address', $event.target.value)">
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <fg-input type="text" label="City" placeholder="City" v-model="user.city"
            @blur="updateUserField('city', $event.target.value)"
            @keydown.enter="updateUserField('city', $event.target.value)">
          </fg-input>
        </div>
        <div class="col-md-4">
          <fg-input type="text" label="Country" placeholder="Country" v-model="user.country"
            @blur="updateUserField('country', $event.target.value)"
            @keydown.enter="updateUserField('country', $event.target.value)">
          </fg-input>
        </div>
        <div class="col-md-4">
          <fg-input label="Postal Code" placeholder="ZIP Code" v-model="user.postal_code"
            @blur="updateUserField('postal_code', $event.target.value)"
            @keydown.enter="updateUserField('postal_code', $event.target.value)">
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <fg-input label="About Me">
            <textarea class="form-control" placeholder="About me" v-model="user.about_me"
              @blur="updateUserField('about_me', $event.target.value)"
              @keydown.enter="updateUserField('about_me', $event.target.value)">
        </textarea>
          </fg-input>
        </div>
      </div>
    </form>

    <div>
      <!-- 
      <vee-input name="referral_code" type="text" placeholder="Referral Code" label="Share Referral Code"
        :value="user.share_referral_code" 
        addon-right-icon="now-ui-icons ui-1_send"
        />
      -->
      <div>
        <router-link to="/affiliates" class="pull-right">          
            <i class='fal fa-external-link'></i> Read about our program          
        </router-link>
        <label for="referral_code">Your Referral Code

        </label>
        <div class="form-group input-group">
          <input type="text" id="referral_code" class="form-control" :value="user.share_referral_code" readonly />
          <div class="input-group-addon input-group-append" style="background-color: #e9ecef;">
            <span class="pointer" @click="copyReferralCode" style="margin-left: 15px;">
              <i class="fal fa-copy"></i> Copy referral
            </span>
          </div>
        </div>
        <small v-if="copySuccess" class="text-success">{{ copySuccess }}</small>
      </div>
      <hr>

      <n-button type="info" :onclick="openTelegram" class="pull-right" v-if="!user.my_telegram_chat_id">
        <i class="fab fa-telegram fa-lg" />&nbsp;
        Register Telegram Alerts and Chat
      </n-button>

      <n-button type="outline" :onclick="disableTelegram" class="pull-right" v-else>
        <i class="fab fa-telegram fa-lg" />&nbsp;
        Unregister Telegram
      </n-button>
    </div>

  </vnud-card>

  <vnud-card>
    <template v-slot:header>
      <h5 class="title">Edit Settings</h5>
    </template>
    <form>
      <div class="row">
        <div class="col-md-2">
          <fg-input label="See debug">
            <br>
            <n-switch v-model="user.my_debug_interface" type="primary" on-text="ON" off-text="OFF"
              v-on:update:modelValue="updateUserField('my_debug_interface', user.my_debug_interface)"></n-switch>
          </fg-input>
        </div>
        <div class="col-md-2">
          <fg-input label="Developer UI">
            <br>
            <n-switch v-model="user.my_dev_interface" type="primary" on-text="ON" off-text="OFF"
              v-on:update:modelValue="updateUserField('my_dev_interface', user.my_dev_interface)"></n-switch>
          </fg-input>
        </div>

        <div class="col-md-2">
          <fg-input label="Clickbait titles!">
            <br>
            <n-switch v-model="user.my_clickbait" type="primary" on-text="ON" off-text="OFF"
              v-on:update:modelValue="updateUserField('my_clickbait', user.my_clickbait)"></n-switch>
          </fg-input>
        </div>

        <div class="col-md-2">
          <fg-input label="Daily Email Summary">
            <br>
            <n-switch v-model="user.my_email_summary" type="primary" on-text="ON" off-text="OFF"
              v-on:update:modelValue="updateUserField('my_email_summary', user.my_email_summary)"></n-switch>
          </fg-input>
        </div>

        <div class="col-md-2">
          <fg-input label="Weekly Email Alerts">
            <br>
            <n-switch v-model="user.my_email_alerts_daily" type="primary" on-text="ON" off-text="OFF"
              v-on:update:modelValue="updateUserField('my_email_alerts_daily', user.my_email_alerts_daily)"></n-switch>
          </fg-input>
        </div>

        <div class="col-md-2">
          <fg-input label="Instant Message Alerts">
            <br>
            <n-switch v-model="user.my_instant_alerts" type="primary" on-text="ON" off-text="OFF"
              v-on:update:modelValue="updateUserField('my_instant_alerts', user.my_instant_alerts)"></n-switch>
          </fg-input>
        </div>

      </div>
    </form>

  </vnud-card>

</template>
<script>
import { mapState, mapActions } from "vuex";
import {
  Switch,
} from "@/components";
import { VeeInput } from "@/components";

export default {
  components: {
    [Switch.name]: Switch, VeeInput,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      copySuccess: "",
    }
  },
  mounted() {
    console.log("PROFILE: Mounted");
    this.$store.dispatch("fetchUser");
  },
  methods: {
    ...mapActions(["updateUserKey"]),
    fallbackCopy() {
      const tempInput = document.createElement("input");
      tempInput.value = this.user.share_referral_code;
      document.body.appendChild(tempInput);
      tempInput.select();
      try {
        document.execCommand("copy");
        this.copySuccess = "Referral code copied!";
      } catch (err) {
        this.copySuccess = "Copy failed. Please try manually.";
      }
      document.body.removeChild(tempInput);
      setTimeout(() => (this.copySuccess = ""), 3000);
    },
    copyReferralCode() {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(this.user.share_referral_code)
          .then(() => {
            this.copySuccess = "Referral code copied!";
            setTimeout(() => (this.copySuccess = ""), 3000);
          })
          .catch(() => {
            this.copySuccess = "Failed to copy. Please try again.";
            setTimeout(() => (this.copySuccess = ""), 3000);
          });
      } else {
        // Fallback for unsupported browsers
        this.fallbackCopy();
      }
    },

    disableTelegram() {
      this.updateUserField('my_telegram_chat_id', '');
    },
    openTelegram() {
      window.open('https://t.me/headingtomars_bot?start=' + encodeURIComponent(this.user.id), '_blank');
    },
    updateUserField(key, value) {
      this.updateUserKey({ key, value });
    },
  },
};
</script>
<style></style>
