<template>
  <div class="row">

    <div class="col-md-12">
      <vnud-card hideCardHeader>
        <template v-slot:header><i v-if="!isLoaded" class="pull-right fa fa-gear fa-spin"></i></template>

        <div class="" v-if="isFirstLoaded">
          <n-table class="table-shopping" :data="watchListTable">
            <template v-slot:columns>
              <th class="text-center"></th>
              <th>Symbol</th>

              <th>Company</th>
              <th class="text-right">Last</th>
              <th class="text-right">Day Chg</th>
              <th class="text-right">Day Chg%</th>
              <th class="text-right">Open</th>
              <th class="text-right">Day Low</th>
              <th class="text-right">Day High</th>

              <th v-if="addMonth" class="text-right">Month%</th>
              <th v-if="addYear" class="text-right">Year%</th>

              <th class="text-right">Exchange</th>

            </template>
            <template v-slot:default="row">
              
              <td>
                <div v-if="row.item.image" class="img-container">
                  <img :src="row.item.image" alt="..." />
                </div>
              </td>
              <td class="td-name pointer">
                <span @click="navigateToCompany(row.item.symbol)">
                  {{
                    row.item.ticker
                  }}</span>
              </td>

              <td>
                <span class="pointer" @click="navigateToCompany(row.item.symbol)">
                  {{ row.item.company_name }}
                </span>
              </td>
              <td class="td-number">
                {{ row.item.currency }}{{ row.item.price?.toFixed(2) }}
              </td>
              <td class="td-number">
                {{ row.item.change?.toFixed(2) }}
              </td>
              <td class="td-number">
                <div :style="{ color: row.item.change > 0 ? 'green' : 'red' }">
                  <i :class="get_animation(row.item.change)"/>
                  {{ row.item.change_pct?.toFixed(2) }}%
                </div>
              </td>

              <td class="td-number">
                <small>{{ row.item.current_open?.toFixed(2) }}</small>
              </td>
              <td class="td-number">
                <small>{{ row.item.day_low?.toFixed(2) }}</small>
              </td>
              <td class="td-number">
                <small>{{ row.item.day_high?.toFixed(2) }}</small>
              </td>

              <td v-if="addMonth" class="td-number" style="font-size:95%">
                <div :style="{ color: row.item.days_31?.change_pct > 0 ? 'green' : 'red' }">
                  <i :class="get_animation(row.item.days_31?.change_pct)"/>
                  {{ row.item.days_31?.close?.toFixed(2) }}<br>
                  {{ row.item.days_31?.change_pct?.toFixed(2) }}%
                </div>
              </td>

              <td v-if="addYear" class="td-number" style="font-size:95%">
                <div :style="{ color: row.item.days_365?.change_pct > 0 ? 'green' : 'red' }">
                  <i :class="get_animation(row.item.days_365?.change_pct)"/>                  
                  {{ row.item.days_365?.close?.toFixed(2) }}<br>
                  {{ row.item.days_365?.change_pct?.toFixed(2) }}%
                </div>
              </td>

              <td class="td-name pointer">
                <span @click="navigateToExchange(row.item.exchange, row.item.ticker)">
                  <span class="pull-right">
                    {{
                      row.item.exchange
                    }}</span>
                </span>
              </td>

            </template>
          </n-table>
          <span v-if="user.my_debug_interface" class="pull-right" style='position:absolute; right: 15px; bottom:10px'>
            <a v-if="apiCall" :href="getDebugApiEntry()" target="_blank">
              &nbsp;&nbsp;<i class="fa fa-gear"></i> Open API </a>
          </span>
        </div>
      </vnud-card>
    </div>
  </div>
</template>

<script>
import globalController from "@/globalController.js";
import globalConfig from "@/globalConfig.js";
import utils from "@/globalUtil.js";
import { mapState } from "vuex";

import {
  VnudCard,
  Table as NTable
} from "@/components";

export default {
  props: {
    symbol: {
      type: String,
      default: () => {
        return "default";
      },
    },
    addMonth: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    addYear: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  components: { VnudCard, NTable, },
  created() {
    console.log(" Component creation ")
    this.loadData();

  },
  data() {
    return {
      apiCall: null,
      counter: 0,
      intervalId: null,
      isLoaded: false,
      isFirstLoaded: false,
      watchListMap: null,
      watchListTable: [

      ],

    };
  },
  mounted() {
    // Set up the interval when the component is mounted
    this.intervalId = setInterval(() => {
      this.counter += 1;
      this.loadData(this.watchlist);
    }, 1000 * 30);
  },
  unmounted() {
    // Clear the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId); // Prevent memory leaks by clearing the interval
    }
  },
  methods: {
    getDebugApiEntry() {
      return globalConfig.getApiPath(this.apiCall);
    },
    navigateToExchange(exchange, symbol) {
      this.$router.push("/company/explore?exchange=" + exchange + "&symbol=" + symbol);
    },
    navigateToCompany(symbol) {
      this.$router.push(globalController.get_route_ticker(symbol));
    },
    get_animation(change) {
      if (change > 20)
        return "fal fa-lg fa-angle-double-up animated-icon-super-success";

      if (change > 10)
        return "fal fa-lg fa-angle-up animated-icon-success";

      if (change > 0)
        return "fal fa-arrow-up";

      if (change < -20)
        return "fal fa-lg fa-angle-double-down animated-icon-super-failure";

      if (change < -10)
        return "fal fa-lg fa-angle-down animated-icon-failure";

      return "fal fa-arrow-down";
    },
    async loadData() {
      this.isLoaded = false;

      this.apiCall = "/company/financials/get/" + encodeURIComponent(this.symbol);

      if (this.addMonth && this.addYear)
        this.apiCall += "?add=31,365";

      globalController.api_call(
        this.apiCall,
        (result) => {
          const itemMap = {};

          this.watchListTable.forEach((item, index) => {
            itemMap[item.symbol] = index;
          });

          this.isLoaded = true;
          this.isFirstLoaded = true;

          let duplicate = {};

          for (var x = 0; x < result.exchange_tickers.length; x++) {
            let ticker = result.exchange_tickers[x];

            // Patch to remove duplicates. Backend should not return duplicates.
            if (ticker in duplicate)
              continue;

            duplicate[ticker] = 1;

            let data = null;

            if (result.financials) {
              data = result.financials[ticker];
              if (!data)
                continue;

              data['symbol'] = ticker;

              if (Object.prototype.hasOwnProperty.call(itemMap, ticker)) {
                // If the item exists, update its properties directly
                const existingItem = this.watchListTable[itemMap[ticker]];
                for (const key in data) {
                  if (Object.prototype.hasOwnProperty.call(existingItem, key)) {
                    existingItem[key] = data[key]; // Update the property value
                  }
                }

                //existingItem['company_name'] = data['company_name'] + " " + this.counter;
                continue;
              } else {
                // DELETE ITEM FROM VIEW
              }

              let p = ticker.split(":");
              data['ticker'] = p[1];
              data['exchange'] = p[0];

              let open = data['current_open'];
              if (open) {

                // [TODO] Fix this use case:
                // We don't have a price, use the previous close.
                // This is wrong but we need to show something.
                
                if (!data['price'])
                  data['price'] = data['previous_close'];

                data['change'] = data['price'] - open;
                data['change_pct'] = ((data['price'] - open) / open) * 100;
              }
            }

            if (!data)
              data = {
                symbol: ticker,
                ticker: "",
                exchange: "",
                image: "",
                company_name: "N/A",
                price: 0,
                currency: "",
                open: 0,
                change: 0,
                change_pct: 0,
                day_low: 0,
                day_high: 0,
              };

            this.watchListTable.push(data)
          }
        },
        (error) => {
          console.log("Failed loading: Error loading watchlist");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
  },
}
</script>


<style>
.action_button {
  margin-right: 10px;
}

.animated-icon-success {
  color: rgb(76, 236, 55);
  animation: blinkGrow 2.5s infinite ease-in-out;
}

.animated-icon-super-success {
  color: rgb(76, 236, 55);
  animation: blinkGrow 1.0s infinite ease-in-out;
}

@keyframes blinkGrow {

  0%,
  100% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(1.5);
  }
}

.animated-icon-failure {
  color: red;
  animation: blinkShrink 2.5s infinite ease-in-out;
}

.animated-icon-super-failure {
  color: red;
  animation: blinkShrink 1.0s infinite ease-in-out;
}

@keyframes blinkShrink {

  0%,
  100% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(0.9);
  }
}
</style>
