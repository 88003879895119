<template>
    <div class="row">
        <NewsTimeline :key="$route.fullPath" :apicall="apiCall">
        </NewsTimeline>
    </div>
</template>
<style>
.ticker {
    text-transform: uppercase;
}
</style>
<script>
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";

import NewsTimeline from "@/views/dashboard/news/NewsTimeline.vue";

export default {
    components: { NewsTimeline },
    created() {

    },
    setLoading() { },
    data() {
        return {
            apiCall: "/news/query?related_exchange_tickers__not__size=0&ai_summary__ne=NULL&order_by=-creation_date&limit=20",
            isLoaded: false,
        };
    },
    watch: {
        "$route.query": {
            handler(newQuery, oldQuery) {

            },
            deep: true, // Allows deep watching of nested properties
            immediate: true, // If you want the handler to be called immediately
        },
    },
};
</script>
<style>
.content {
    max-width: 100vw;
    overflow: auto;
    /* add scrolling if content overflows */
}
</style>
