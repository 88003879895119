<template>
  <div class="row">
    <div class="col-md-12">
      <vnud-card>
        <template v-slot:header>
          <h4 class="card-title ticker">List of news for my topics</h4>
        </template>
      </vnud-card>
    </div>
  </div>
</template>
<style>
.ticker {
  text-transform: uppercase;
}
</style>
<script>
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";

export default {
  components: {},
  created() {
    const queryParams = this.$route.query;
    this.loadData(queryParams.company_name);
  },
  data() {
    return {
      name: "loading...",
      isLoaded: false,
    };
  },
  watch: {
    ticker: {
      immediate: true, // Executes the watcher immediately on component mount
    },
  },
  methods: {
    async loadData(safe_name) {
      this.isLoaded = false;
      
      if (!safe_name)
        this.$router.push("/company/explore?nothing_here=1");
      
      globalController.api_call(
        "/company/query?safe_name=" + encodeURIComponent(safe_name),
        (result) => {
          console.log("Company result: Result");
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
  },
};
</script>
<style></style>
