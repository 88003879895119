<template>
  <div class="row">

    <div class="col-md-12" v-if="isFirstLoaded">
      <h4 class="card-title" v-if="!isLoaded"><i class="pull-right fa fa-gear fa-spin"></i></h4>
      <vnud-card v-if="!noWatchlist">
        <template v-slot:header>
          <span class="pull-right"><small>{{ this.counter }} </small></span>
          <h4 class="card-title">Watchlist <i v-if="!isLoaded" class="pull-right fa fa-gear fa-spin"></i></h4>
        </template>

        <div class="table" v-if="isFirstLoaded">
          <n-table class="table-watch" :data="watchListTable">
            <template v-slot:columns>
              <th class="text-center"></th>
              <th>Symbol</th>

              <th>Company</th>
              <th class="text-right">Last</th>
              <th class="text-right col-small-hide">Chg</th>
              <th class="text-right">Day Chg%</th>
              <th class="text-right col-small-hide">Week %</th>
              <th class="text-right ">Month %</th>
              <th class="text-right col-small-hide">Year %</th>

              <th class="text-right col-small-hide">Exchange</th>
              <th></th>
            </template>
            <template v-slot:default="row">
              <td>
                <div v-if="row.item.image" class="img-container">
                  <img :src="row.item.image" alt="..." />
                </div>
              </td>
              <td class="td-name pointer">
                <span @click="navigateToCompany(row.item.symbol)">
                  {{
                    row.item.ticker
                  }}</span>
              </td>

              <td>
                <span class="pointer" @click="navigateToCompany(row.item.symbol)">
                  <small>{{ row.item.company_name }}</small>
                </span>
              </td>
              <td class="td-number">
                {{ row.item.currency }}
                <span class="form-text" v-if="row.item.price">
                  {{ row.item.price.toFixed(2) }}
                </span>
                <span v-else>
                  N/A
                </span>
              </td>
              <td class="td-number ">
                <span class="form-text" v-if="row.item.change" style="font-size:75%">
                  <div :style="{ color: row.item.change > 0 ? '' : 'red' }">
                    {{ row.item.change.toFixed(2) }}
                  </div>
                </span>
                <span v-else>
                  --
                </span>
              </td>

              <td class="td-number col-small-hide">
                <span class="form-text" v-if="row.item.change_pct" style="font-size:75%">
                  <div :style="{ color: row.item.change_pct > 0 ? '' : 'red' }">
                    <i :class="get_animation(row.item.change_pct)" />
                    {{ row.item.change_pct.toFixed(2) }}%
                  </div>
                </span>
                <span v-else>
                  --
                </span>
              </td>

              <td class="td-number col-small-hide">
                <span class="form-text" v-if="row.item.change_pct_8" style="font-size:75%">
                  <div :style="{ color: row.item.change_pct_8 > 0 ? '' : 'red' }">
                    <i :class="get_animation(row.item.change_pct_8)" />
                    {{ row.item.change_pct_8 }}%
                  </div>
                </span>
                <span v-else>
                  --
                </span>
              </td>
              <td class="td-number">
                <span class="form-text" v-if="row.item.change_pct_31" style="font-size:75%">
                  <div :style="{ color: row.item.change_pct_31 > 0 ? '' : 'red' }">
                    <i :class="get_animation(row.item.change_pct_31)" />
                    {{ row.item.change_pct_31 }}%
                  </div>
                </span>
                <span v-else>
                  --
                </span>
              </td>
              <td class="td-number col-small-hide">
                <span class="form-text" v-if="row.item.change_pct_365" style="font-size:75%">
                  <div :style="{ color: row.item.change_pct_365 > 0 ? '' : 'red' }">
                    <i :class="get_animation(row.item.change_pct_365)" />
                    {{ row.item.change_pct_365 }}%
                  </div>
                </span>
                <span v-else>
                  --
                </span>
              </td>
              <td class="td-name pointer col-small-hide">
                <span @click="navigateToExchange(row.item.exchange, row.item.ticker)">
                  <div class="pull-right">
                    {{
                      row.item.exchange
                    }}</div>
                </span>
              </td>
              <td class="td-name pointer">
                <span class="action_button pull-right" @click="remove_from_watchlist(row.item.symbol)">
                  <i class='now-ui-icons ui-1_simple-remove'></i>
                </span>
              </td>

            </template>
          </n-table>
        </div>
        <n-button class="pull-right" type="primary" @click="clear_watchlist"> Clear </n-button>
      </vnud-card>
      <div>
        <vnud-card v-if="noWatchlist">
          <template v-slot:header>
            <span class="pull-right"><small>{{ this.counter }} </small></span>
            <h3 class="card-title">Please use search to register some companies to your watchlist. <i v-if="!isLoaded"
                class="pull-right fa fa-gear fa-spin"></i></h3>
            Here are some recommendations of companies to watch.
          </template>
        </vnud-card>
      </div>
    </div>
  </div>
</template>

<script>
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";
import { mapState, mapActions } from "vuex";

import {
  VnudCard,
  Table as NTable
} from "@/components";

export default {
  props: {
    watchlist: {
      type: String,
      default: () => {
        return "default";
      },
    },
  },
  components: { VnudCard, NTable, },
  created() {
    console.log(" Component creation ")
    this.loadData(this.watchlist);
  },
  
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
      highlight_search: (state) => state.highlight_search,
    }),
  },
  data() {
    return {
      counter: 0,
      intervalId: null,
      isLoaded: false,
      isLoadedRecommendations: false,
      isFirstLoaded: false,
      watchListMap: null,
      recommendationsTable: [],
      watchListTable: [

      ],
      noWatchlist: false,
    };
  },
  mounted() {
    // Set up the interval when the component is mounted    
    this.intervalId = setInterval(() => {
      this.counter += 1;
      this.loadData(this.watchlist);
    }, 1000 * 10);
  },
  unmounted() {
    // Clear the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId); // Prevent memory leaks by clearing the interval
    }
  },
  methods: {
    ...mapActions(["highlightSearch"]),    
    get_animation(change) {
      if (change > 20)
        return "icon_position fal fa-lg fa-angle-double-up animated-icon-super-success";

      if (change > 10)
        return "icon_position fal fa-lg fa-angle-up animated-icon-success";

      if (change > 0)
        return "icon_position fal fa-arrow-up";

      if (change < -20)
        return "icon_position fal fa-lg fa-angle-double-down animated-icon-super-failure";

      if (change < -10)
        return "icon_position fal fa-lg fa-angle-down animated-icon-failure";

      return "icon_position fal fa-arrow-down";
    },

    add_to_watchlist(symbol) {
      let item = this.watchListTable.find(item => item.symbol === symbol);
      if (item) {
        console.log("Already in watchlist");
        return
      }

      globalController.api_call(
        "/ticker/user/watchlist/append/" + encodeURIComponent(this.watchlist) + "/" + encodeURIComponent(symbol),
        (result) => {
          this.loadData(this.watchlist);
        },
        (error) => {
          console.log("Failed loading: Error loading watchlist");
        }
      );
    },

    remove_from_watchlist(symbol) {
      let item = this.watchListTable.find(item => item.symbol === symbol);
      item['status'] = "deleted";

      globalController.api_call(
        "/ticker/user/watchlist/remove_ticker/" + encodeURIComponent(this.watchlist) + "/" + encodeURIComponent(symbol),
        (result) => {
          item['status'] = "gone";
        },
        (error) => {
          console.log("Failed loading: Error loading watchlist");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );

    },
    navigateToExchange(exchange, symbol) {
      this.$router.push("/company/explore?exchange=" + exchange + "&symbol=" + symbol);
    },
    navigateToCompany(symbol) {
      this.$router.push(globalController.get_route_ticker(symbol));
    },
    clear_watchlist() {
      globalController.api_call(
        "/ticker/user/watchlist/rm/" + encodeURIComponent(this.watchlist),
        (result) => {
          console.log(" DELETED LIST ");
          this.watchListTable = [];
        },
        (error) => {
          console.log("Failed loading: Error loading watchlist");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
    async loadRecommendations() {
      this.isLoadedRecommendations = false;
      let conditions = "change_pct_365__gte=10&change_pct_31__gte=5&change_pct_1__gte=-5&order_by=-change_pct_31&limit=20";

      globalController.api_call(
        "/ticker/query/simple?" + conditions,
        (result) => {
          console.log("Recommendations result");
          this.isLoadedRecommendations = true;
          this.recommendationsTable = result.tickers;
        },
        (error) => {
          this.isLoadedRecommendations = true;
          console.log("Failed loading: Error loading watchlist");
        }
      );
    },
    async loadData(watchlist) {
      this.isLoaded = false;
      globalController.api_call(
        "/ticker/user/watchlist/get/" + encodeURIComponent(watchlist) + "?add_financials=1",
        (result) => {
          console.log("Watchlist result");

          const itemMap = {};
          this.watchListTable.forEach((item, index) => {
            itemMap[item.symbol] = index;
          });

          this.isLoaded = true;
          this.isFirstLoaded = true;

          this.noWatchlist = (result.exchange_tickers.length == 0);

          this.highlightSearch(this.noWatchlist);

          for (var x = 0; x < result.exchange_tickers.length; x++) {
            let ticker = result.exchange_tickers[x];

            let data = null;

            if (result.financials) {
              data = result.financials[ticker];
              data['symbol'] = ticker;

              if (Object.prototype.hasOwnProperty.call(itemMap, ticker)) {
                // If the item exists, update its properties directly
                const existingItem = this.watchListTable[itemMap[ticker]];
                for (const key in data) {
                  if (Object.prototype.hasOwnProperty.call(existingItem, key)) {
                    existingItem[key] = data[key]; // Update the property value
                  }
                }

                //existingItem['company_name'] = data['company_name'] + " " + this.counter;
                continue;
              } else {
                // DELETE ITEM FROM VIEW
              }

              let p = ticker.split(":");
              data['ticker'] = p[1];
              data['exchange'] = p[0];

              let open = data['current_open'];
              if (open) {
                data['change'] = data['price'] - open;
                data['change_pct'] = ((data['price'] - open) / open) * 100;
              }
            }

            if (!data)
              data = {
                symbol: ticker,
                ticker: "",
                exchange: "",
                image: "",
                company_name: "N/A",
                price: 0,
                currency: "",
                open: 0,
                change: 0,
                change_pct: 0,
                day_low: 0,
                day_high: 0,
              };

            this.watchListTable.push(data)
          }
        },
        (error) => {
          console.log("Failed loading: Error loading watchlist");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
  },
}
</script>


<style scoped>
.icon_position {
  float: right;
  margin-left: 10px;
}

.action_button {
  margin-right: 10px;
}
</style>
