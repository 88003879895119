<template>
  <div class="row margin_top">
    <div class="col-md-12">

      <stats-card type="info" title="Share to the moon!" :subTitle="news_title" icon="fa fa-share" @click="openLink()"
        @icon-click="shareArticleNavigator" class="pointer">
        <template v-slot:footer>
          <n-button v-if="navigatorShare" class="fal fa-share fa-bg" type="neutral" @click="shareArticleNavigator()">
            Share
          </n-button>

          <n-button class="fal fa-clipboard" type="neutral" @click="copyToClipboard()">
            Copy to clipboard
          </n-button>

          <n-button v-if="isSamePage" class="fal fa-external-link" type="neutral" @click="openLink()">
            Open new tab
          </n-button>

          <n-button class="btn-twitter" type="neutral" @click="shareOnTwitter()">
            <i class="fab fa-twitter"></i> Twitter
          </n-button>

          <n-button class="btn-linkedin" type="neutral" @click="shareOnLinkedIn()">
            <i class="fab fa-linkedin"></i> Linkedin
          </n-button>

          <n-button class="btn-github" type="neutral" @click="shareOnGitHubGist()">
            <i class="fab fa-github"></i> Github
          </n-button>

          <n-button class="btn-reddit" type="neutral" @click="shareOnReddit()">
            <i class="fab fa-reddit"></i> Reddit
          </n-button>

          <n-button class="btn-facebook" type="neutral" @click="shareOnFacebook()">
            <i class="fab fa-facebook-square"></i> Facebook
          </n-button>
        </template>
      </stats-card>

    </div>
  </div>
</template>
<script>
import {
  StatsCard,
} from "@/components";

import utils from "@/globalUtil.js";
import GlobalController from "../../globalController";

export default {
  components: {
    StatsCard,
  },
  name: "share_url",
  props: {
    news_id: {
      type: String,
      default: "",
    },
    news_title: {
      type: String,
      default: "",
    },
  },
  computed: {
    navigatorShare() {
      return navigator.share;
    },
    isSamePage() {
      return this.getLink() != window.location.href;
    }
  },
  methods: {
    openInNewWindow(url) {
      window.open(url, "_blank", "noopener,noreferrer");
    },
    shareArticleNavigator() {      
      if (navigator.share) {
        navigator.share({
          title: "HEADMARS.com Join the best stock market news platform!",
          text: this.news_title,
          url: this.getLink(),
        });
      } else {        
        window.open(this.getLink(), "_blank", "noopener,noreferrer");
      }
    },
    shareOnTwitter() {
      const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.getLink())}&text=${encodeURIComponent(this.news_title)}`;
      this.openInNewWindow(twitterUrl);
    },
    shareOnLinkedIn() {
      const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(this.getLink())}&title=${encodeURIComponent(this.news_title)}`;
      this.openInNewWindow(linkedInUrl);
    },
    shareOnReddit() {
      const redditUrl = `https://www.reddit.com/submit?url=${encodeURIComponent(this.getLink())}&title=${encodeURIComponent(this.news_title)}`;
      this.openInNewWindow(redditUrl);
    },
    shareOnFacebook() {
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.getLink())}&quote=${encodeURIComponent(this.news_title)}`;
      this.openInNewWindow(facebookUrl);
    },
    shareOnGitHubGist() {
      const gistUrl = `https://gist.github.com/new`;
      this.openInNewWindow(gistUrl);
    },
    navigationLink() {
      return GlobalController.get_news_link({ 'id': this.news_id, 'title': this.news_title });
    },
    getLink() {            
      console.log(" Origin " + window.location.origin);
      if (window.location.origin == "https://headingtomars.com") {
        return `https://headmars.com${this.navigationLink()}`;
      }
      return `${window.location.origin}${this.navigationLink()}`;
    },
    openLink() {
      if (this.isSamePage) {
        this.shareArticleNavigator();
        return;
      }
      this.$router.push(this.navigationLink());
    },
    toast(text, color) {
      utils.runToast(
        text,
        "now-ui-icons ui-1_bell-53",
        "bottom-right",
        color
      );
    },

    async copyToClipboard() {
      let text = this.getLink();
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        try {
          await navigator.clipboard.writeText(text);
          this.toast(` Link copied to clipboard `, "info");
        } catch (error) {
          this.toast(` Failed to copy to clipboard `, "danger");
        }
        return;
      }

      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'fixed'; // Prevents scrolling to bottom
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        this.toast(` Link copied to clipboard `, "info");
      } catch (error) {
        this.toast(` Failed to copy to clipboard (l)`, "danger");
      } finally {
        document.body.removeChild(textArea);
      }

    },
  },
};
</script>
<style scoped>
.margin_top {
  margin-top: 15px;
}
</style>
