<template>
  <div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto text-center ">
        <div class="card-white">
          <h2 class="title text-white">Thank you <i class="fal fa-heart"/> <br> for choosing to subscribe!</h2>
          <h4 class="title text-white"><i class="fas fa-gem"/></h4>
          <div class="description">
            <p>
              We're thrilled to welcome you to our community and are excited to bring
              you all the valuable insights, updates, and resources that come with your subscription.
            </p>
            <p>
              Your support means the world to us, and we're committed to providing you with an exceptional experience.
              If
              you have any questions or need assistance with your subscription, please feel free to reach out.
            </p>
            Once again, thank you for your trust and enthusiasm. We look forward to embarking on this journey together!
          </div>

          <h4 class="title text-white"></h4>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import globalController from "@/globalController.js";
import { mapState, mapActions } from "vuex";

export default {
  name: "vnud-success",
  data() {
    return {
      email: 'contact@engineer.blue',
      subject: 'Inquiry',
      body: 'Hello, I would like to inquire about tothemoon corporate...',
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
    mailtoLink() {
      const mailtoLink = `subject=${encodeURIComponent(this.subject)}&body=${encodeURIComponent(this.body)}`;
      return mailtoLink;
    },
  },
  methods: {
    addToCart(tier) {
      //debugger;
      if (!this.logged_in) {
        this.$router.push("/register?trial=" + tier);
        return;
      }

      globalController.api_call(
        "/payment/create_checkout_session?product=" + encodeURIComponent(tier),
        async (result) => {
          window.location.href = result.url;
        },
        (error) => {
          console.log("Failed loading: Error loading " + error);
        }
      );

    },
    contactUs() {
      this.$router.push("/contact?" + this.mailtoLink);
    },
  }
};
</script>

<style>
.text-white {
  color: #fff;
}
</style>

<style scoped>
a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>