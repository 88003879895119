<template>
  <div class="user user__menu">
    <div class="photo">
      <img :src="image" alt="avatar" />
    </div>
    <div class="info">
      <a data-toggle="collapse" href="#userMenu" role="button" aria-controls="userMenu" aria-expanded="false">
        <span>
          {{ userFirstName }} {{ userLastName }}
          <b class="caret"></b>
        </span>
      </a>
      <div class="collapse" id="userMenu">
        <ul class="nav user-menu__nav">
          <slot>
            <sidebar-item v-if="user.is_admin" :link="{ icon: 'fal fa-bug', name: 'ADMIN', path: '/admin/panel' }" />
            <sidebar-item :link="{ icon: 'now-ui-icons users_single-02', name: 'User Profile', path: '/pages/user' }"></sidebar-item>
            <sidebar-item :link="{ icon: 'now-ui-icons ui-2_favourite-28', name: 'Referrals', path: '/affiliates' }"></sidebar-item>
            <sidebar-item v-if="user.subscription"
              :link="{ icon: 'fal fa-gem', name: 'Upgrade_Subscription', path: '/pricing' }" />
            <sidebar-item :link="{ icon: 'now-ui-icons users_circle-08', name: 'Logout', path: '/login' }"></sidebar-item>
          </slot>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "user-menu",
  computed: {
    ...mapState({
      userFirstName: (state) => state.user.first_name,
      userLastName: (state) => state.user.last_name,
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
  },
  props: {
    title: {
      type: String,
      default: "TITLE",
    },
    image: {
      type: String,
      default: "/img/default-avatar.png",
    },
  },
};
</script>
<style>
.user__menu ul.user-menu__nav {
  margin-top: 0;
  padding-top: 20px;
}
</style>
