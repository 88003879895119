import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import { ref, getCurrentInstance } from 'vue';

import DashboardLayout from "../views/dashboard/layout/DashboardLayout.vue";
import AuthLayout from "../views/dashboard/pages/AuthLayout.vue";
import MainLayout from "../views/dashboard/layout/MainLayout.vue";

// Page Headers
import DashboardHeader from "../views/dashboard/dashboard/DashboardHeader.vue";
import DefaultHeader from "../views/dashboard/headers/DefaultHeader";
import SweetAlertHeader from "../views/dashboard/headers/SweetAlertHeader";
import VectorMapsHeader from "../views/dashboard/headers/VectorMapsHeader";
import CalendarHeader from "../views/dashboard/headers/CalendarHeader";

// GeneralViews
import NotFound from "../views/generalViews/NotFoundPage.vue";

// Dashboard pages
import Dashboard from "../views/dashboard/dashboard/Vnud-Dashboard.vue";
import Widgets from "../views/dashboard/dashboard/Vnud-Widgets.vue";
import Charts from "../views/dashboard/dashboard/Vnud-Charts.vue";
import Calendar from "../views/dashboard/dashboard/Vnud-Calendar.vue";

// Pages
import Timeline from "../views/dashboard/pages/Vnud-Timeline.vue";
import UserProfile from "../views/dashboard/pages/UserProfile.vue";
import AIPrompt from "../views/dashboard/pages/AIPrompt.vue";
import AIPromptChat from "../views/dashboard/pages/AIPromptChat.vue";

import globalController from "@/globalController.js"

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "prompt",
      name: "AI Prompt Edit",
      components: { default: AIPrompt, header: DefaultHeader },
    },
    {
      path: "ai_chat",
      name: "AI Chat",
      components: { default: AIPromptChat, header: DefaultHeader },
    },
    {
      path: "user",
      name: "User Page",
      components: { default: UserProfile, header: DefaultHeader },
    },
    {
      path: "timeline",
      name: "Timeline Page",
      components: { default: Timeline, header: DefaultHeader },
    },
  ],
};

// Components pages
import Buttons from "../views/dashboard/components/Vnud-Buttons.vue";
import GridSystem from "../views/dashboard/components/GridSystem.vue";
import Panels from "../views/dashboard/components/Vnud-Panels.vue";
import SweetAlert from "../views/dashboard/components/SweetAlert.vue";
import Notifications from "../views/dashboard/components/Vnud-Notifications.vue";
import Icons from "../views/dashboard/components/Vnud-Icons.vue";
import Typography from "../views/dashboard/components/Vnud-Typography.vue";

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons, header: DefaultHeader },
    },
    {
      path: "grid-system",
      name: "Grid System",
      components: { default: GridSystem, header: DefaultHeader },
    },
    {
      path: "panels",
      name: "Panels",
      components: { default: Panels, header: DefaultHeader },
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      components: { default: SweetAlert, header: SweetAlertHeader },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications, header: DefaultHeader },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons, header: DefaultHeader },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography, header: DefaultHeader },
    },
  ],
};

// Form Pages
import RegularForms from "../views/dashboard/forms/RegularForms.vue";
import StockRegister from "../views/dashboard/stock/StockRegister.vue";
import MainSite from "../views/dashboard/MainSite.vue";
import MainSiteAnalysis from "../views/dashboard/MainSiteAnalysis.vue";


import StockSearch from "../views/dashboard/stock/StockSearch.vue";
import CompanyView from "../views/dashboard/company/CompanyView.vue";
import CompanyExplore from "../views/dashboard/company/CompanyExplore.vue";
import CompanyNews from "../views/dashboard/company/CompanyNews.vue";
import CompanyReview from "../views/dashboard/company/CompanyReview.vue";
import ExtendedForms from "../views/dashboard/forms/ExtendedForms.vue";
import ValidationForms from "../views/dashboard/forms/ValidationForms.vue";
import Wizard from "../views/dashboard/forms/Vnud-Wizard.vue";
import NewsExploration from "../views/dashboard/news/NewsExploration.vue";


let adminMenu = {
  path: "/admin",
  component: DashboardLayout,
  redirect: "/admin/panel",
  name: "Admin Panel",
  children: [
    {
      path: "panel",
      name: "Admin panel",
      components: { default: AdminPanel, header: DefaultHeader },
    },
  ],
};

let stocksMenu = {
  path: "/stock",
  component: DashboardLayout,
  redirect: "/stock/register",
  name: "Stock",
  children: [
    {
      path: "register",
      name: "Watch a company",
      components: { default: StockRegister, header: DefaultHeader },
    },
  ],
};

let analysisMenu = {
  path: "/analysis",
  component: DashboardLayout,
  redirect: "/analysis/latest",
  name: "Company Analysis",
  children: [
    {
      path: "latest",
      name: "Latest Company Analysis",
      components: { default: CompanyReview, header: DefaultHeader },
      props: {
        default: {},
      },
      meta: {
        canonical: "https://headingtomars.com/analysis/latest",
        title: 'AI News: Company Analysis'
      },
    },
    {
      path: ":symbol",
      name: `Analysis`,
      components: { default: CompanyReview, header: DefaultHeader },
      props: {
        default: (route) => ({
          apicall: `/company/query_symbol/${route.params.symbol.replace(/_/g, ' ')}?order_by=-creation_date`,
          title: `Analysis of ${route.params.symbol.replace(/_/g, ' ')}`,
        }),
      },
    },
  ]
}

let newsMenu = {
  path: "/news",
  component: DashboardLayout,
  redirect: "/news/latest_ai",
  name: "News",
  children: [
    {
      path: "view",
      name: "Article",
      components: { default: NewsExploration, header: DefaultHeader },
      props: {
        default: { show_chat_panel: true, disable_load: true },
      },
    },
    {
      path: "my_portfolio",
      name: "My Portfolio",
      components: { default: NewsExploration, header: DefaultHeader },
      props: {
        default: { apicall: "/news/my_portfolio?order_by=-creation_date&limit=20" },
      },
    },
    {
      path: "latest_ai",
      name: "Latest AI news",
      components: { default: NewsExploration, header: DefaultHeader },
      props: {
        default: { apicall: "/news/query?ai_summary__ne=NULL&order_by=-creation_date&limit=20" },
      },
    },
    {
      path: "latest",
      name: "Latest news",
      components: { default: NewsExploration, header: DefaultHeader },
      props: {
        default: { apicall: "/news/query?order_by=-creation_date&limit=20&no_cache=1" }, // &creation_date__lte=1 hour
      },
    },
    {
      path: "bullish",
      name: "Bullish news",
      components: { default: NewsExploration, header: DefaultHeader },
      props: {
        default: { apicall: "/news/query?sentiment_score__gte=7&order_by=-creation_date&limit=20" },
      },
    },
    {
      path: "bearish",
      name: "Bearish news",
      components: { default: NewsExploration, header: DefaultHeader },
      props: {
        default: { apicall: "/news/query?sentiment_score__lte=-5&order_by=-creation_date&limit=20" },
      },
    },
    {
      path: "defcon",
      name: "Alerts",
      components: { default: NewsExploration, header: DefaultHeader },
      props: {
        default: { apicall: "/news/query?AI__defcon_level__lte=2&order_by=-creation_date&limit=20" },
      },
    },
    {
      path: "class/:type",
      name: `Classification`,
      components: { default: NewsExploration, header: DefaultHeader },
      props: {
        default: (route) => ({
          apicall: `/news/query?AI__classification=${route.params.type.replace(/_/g, ' ')}&order_by=-creation_date&limit=20`,
        }),
      },
    },
    {
      path: "publisher/:type",
      name: `Publisher`,
      components: { default: NewsExploration, header: DefaultHeader },
      props: {
        default: (route) => ({
          apicall: `/news/query?publisher=${route.params.type.replace(/_/g, ' ')}&order_by=-creation_date&limit=20`,
        }),
      },
    },
    {
      path: "experiments",
      name: "Experiments",
      components: { default: NewsExploration, header: DefaultHeader },
      props: {
        default: { apicall: "/news/query?experiment__ne=NULL" },
      },
    },
  ],
};

let portfolioMenu = {
  path: "/portfolio",
  component: DashboardLayout,
  redirect: "/portfolio/register",
  name: "Track Purchase",
  children: [
    {
      path: "register",
      name: "Track a shares purchase",
      components: { default: StockRegister, header: DefaultHeader },
    },
  ],
};


let companyMenu = {
path: "/company",
  component: DashboardLayout,
  redirect: "/company/explore",
  name: "Company View",
  children: [
    {
      path: "explore",
      name: "Company exploration",
      components: { default: CompanyExplore, header: DefaultHeader },
    },
    {
      path: "view",
      name: "Company view",
      components: { default: CompanyView, header: DefaultHeader },
    },
    {
      path: "news",
      name: "Company recent news",
      components: { default: CompanyNews, header: DefaultHeader },
    },  
  ],
};

let tickerMenu = {
  path: "/ticker",
  component: DashboardLayout,
  redirect: "/company/explore",
  name: "Company Ticker",
  children: [
    {
      path: ":symbol",
      name: `Company`,
      components: { default: CompanyView, header: DefaultHeader },
      props: {
        default: (route) => ({
          full_ticker: `${route.params.symbol}`,
        }),
      },
    },    
  ],
};

let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  name: "Forms",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      components: { default: RegularForms, header: DefaultHeader },
    },
    {
      path: "extended",
      name: "Extended Forms",
      components: { default: ExtendedForms, header: DefaultHeader },
    },
    {
      path: "validation",
      name: "Validation Forms",
      components: { default: ValidationForms, header: DefaultHeader },
    },
    {
      path: "wizard",
      name: "Wizard",
      components: { default: Wizard, header: DefaultHeader },
    },
  ],
};

// Tables Pages
import RegularTables from "../views/dashboard/tables/RegularTables.vue";
import ExtendedTables from "../views/dashboard/tables/ExtendedTables.vue";
import PaginatedTables from "../views/dashboard/tables/PaginatedTables.vue";

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  name: "Tables",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      components: { default: RegularTables, header: DefaultHeader },
    },
    {
      path: "extended",
      name: "Extended Tables",
      components: { default: ExtendedTables, header: DefaultHeader },
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      components: { default: PaginatedTables, header: DefaultHeader },
    },
  ],
};

// Maps Pages
import GoogleMaps from "../views/dashboard/maps/GoogleMaps.vue";
import FullScreenMap from "../views/dashboard/maps/FullScreenMap.vue";
import VectorMaps from "../views/dashboard/maps/VectorMaps.vue";

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps, header: DefaultHeader },
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      meta: {
        hideContent: true,
        hideFooter: true,
      },
      components: { default: FullScreenMap },
    },
    {
      path: "vector-map",
      name: "Vector Map",
      components: { default: VectorMaps, header: VectorMapsHeader },
    },
  ],
};

// Auth Pages
import Pricing from "../views/dashboard/pages/Vnud-Pricing.vue";
import Success from "../views/dashboard/pages/Vnud-Success.vue";
import AffiliateProgram from "../views/dashboard/pages/Vnud-AffiliateProgram.vue";
import Error from "../views/dashboard/pages/Vnud-Error.vue";
import EmailValidation from "../views/dashboard/pages/Vnud-EmailValidation.vue";
import Cancel from "../views/dashboard/pages/Vnud-Cancel.vue";
import About from "../views/dashboard/pages/Vnud-About.vue";
import Contact from "../views/dashboard/pages/Vnud-Contact.vue";
import Lock from "../views/dashboard/pages/Vnud-Lock.vue";
import PasswordRecovery from "../views/dashboard/pages/Vnud-PasswordRecovery.vue";
import Register from "../views/dashboard/pages/Vnud-Register.vue";
import Login from "../views/dashboard/pages/Vnud-Login.vue";
import AdminPanel from "../views/dashboard/admin/AdminPanel.vue";

let mainPages = {
  path: "/",
  component: MainLayout,
  name: "Main",
  
  children: [
    {
      path: "",
      name: "Main",
      components: { default: CompanyReview, header: DefaultHeader },
      props: {
        default: {},
      },
      meta: {
        canonical: "https://headingtomars.com/analysis/latest",
        title: 'AI News: Company Analysis'
      },

    },
    {
      path: "/home",
      name: "Home",
      component: MainSite,
      meta: {
        canonical: "https://headingtomars.com/home",
        title: 'Straight to the point Stock News, Beyond the Moon!'
      },
    },

    {
      path: "/search",
      name: "Search",
      component: StockSearch,
      meta: {
        canonical: "https://headingtomars.com/search",
        title: 'AI Stock News: Search'
      },
    },
  ],
};

let authPages = {
  path: "/a",
  component: MainLayout,
  name: "Authentication",
  children: [
    {
      path: "/contact",
      name: "Contact",
      component: Contact,
      meta: { noActionBar: true, backgroundImage: "/citygirl/citygirl2.png", },
    },
    {
      path: "/login",
      name: "Login",
      meta: { noActionBar: true, backgroundImage: "/citygirl/citygirl2.png", },
      component: Login,
    },
    {
      path: "/register",
      name: "Register",
      meta: { noActionBar: true, backgroundImage: "/img/bg3.jpg", },
      component: Register,
    },
    {
      path: "/pricing",
      name: "Pricing",
      meta: { noActionBar: true, backgroundImage: "/citygirl/citygirl2.png", backgroundVideo: "/citygirl/isabella_citygirl_looks_up.mp4", },
      component: Pricing,
    },
    {
      path: "/affiliates",
      name: "Affiliate Program",
      meta: { noActionBar: true, backgroundImage: "/citygirl/citygirl2.png", backgroundVideo: "/citygirl/isabella_says_hi.mp4", },
      component: AffiliateProgram,
    },
    {
      path: "/success",
      name: "Success",
      meta: { noActionBar: true, backgroundImage: "/citygirl/citygirl2.png", },
      component: Success,
    },
    {
      path: "/error",
      name: "Error",
      meta: { noActionBar: true, backgroundImage: "/citygirl/citygirl2.png", },
      component: Error,
    },
    {
      path: "/please_wait",
      name: "Wait validation",
      meta: { noActionBar: true, backgroundImage: "/citygirl/citygirl2.png", },
      component: EmailValidation,
    },

    {
      path: "/Cancel",
      name: "Cancel",
      component: Cancel,
      meta: { noActionBar: true, backgroundImage: "/citygirl/citygirl2.png", },
    },
    /*    
        {
          path: "/trial",
          name: "Trial",
          component: Payment,
        },
    */
    {
      path: "/about",
      name: "About",
      component: About,
      meta: {
        noActionBar: true, backgroundImage: "/img/bg15.jpg",
        canonical: 'https://headingtomars.com/about',
        title: 'About Us'
      },
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
      meta: {
        title: 'Lock Screen'
      },
    },
    {
      path: "/password_recovery",
      name: "Password Recovery",
      component: PasswordRecovery,
      meta: {
        title: 'Password Recovery'
      },
    },
  ],
};

const routes = [
  {
    path: '/n/:news_id',
    redirect: (to) => {      
      return {
        path: '/news/view',
        query: { id: to.params.news_id },
        hash: to.hash,
      };            
    }
  },  
  newsMenu,
  analysisMenu,
  stocksMenu,
  portfolioMenu,
  companyMenu,
  authPages,
  mainPages,
  pagesMenu,
  componentsMenu,
  formsMenu,
  tickerMenu,
  tablesMenu,
  adminMenu,
  mapsMenu,
  {
    path: "/dashboard",
    component: DashboardLayout,    
    name: "Dashboard",
    children: [
      {
        path: "",
        name: "Dashboard",
        components: { default: Dashboard, header: DashboardHeader },
      },
      {
        path: "/calendar",
        name: "Calendar",
        components: { default: Calendar, header: CalendarHeader },
      },
      {
        path: "/charts",
        name: "Charts",
        components: { default: Charts, header: DefaultHeader },
      },
      {
        path: "/widgets",
        name: "Widgets",
        components: { default: Widgets, header: DefaultHeader },
      },
    ],
  },
  { path: "/:pathMatch(.*)*", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // If navigating back, scroll to the previous position
    } else {
      return { top: 0 }; // Always scroll to the top of the page for new routes
    }
  }
});

const privatePaths = ['/dashboard', '/pages', '/news'];

router.beforeEach((to, from, next) => {
  if (to.fullPath.includes("/error")) {    
    next();
    return;
  }
  
  const token = localStorage.getItem("userToken");
  if (!token) {
      next(); // Redirect to login if not authenticated
  } else {
    globalController.get_currentuser(
      (user) => {
        console.log(" NEXT VIEW " + to.path);
          next();
      },
      (error) => {        
        console.log("INDEX: Error loading user");
        next("/error")
      },
      true // Invalidate when we change view
    )
  }
});

export default router;
