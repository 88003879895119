<template>
  <div class="error-page">
    <div class="row h-100">
      <div class="col-md-6 ml-auto mr-auto text-center d-flex align-items-center">
        <div class="card-white p-5">
          <div class="error-icon mb-4">
            <i class="fal fa-bug fa-4x text-warning animate-bounce"/>            
          </div>          
          
          <h2 class="title text-white mb-4">Oops! Something went wrong</h2>
          
          <div class="description mb-4">
            <h4 class="text-white" v-if="errorMessage">{{ errorMessage }}</h4>
            <h4 class="text-white" v-else>An unexpected error occurred</h4>
            It is not you!, it is me!
          </div>

          <button class="btn btn-warning btn-round" @click="goHome">
            <i class="fal fa-home mr-2"></i>Back to Home
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalController from "@/globalController.js";
import { mapState, mapActions } from "vuex";

export default {
  name: "vnud-error",
  data() {
    return {
      errorMessage: this.$route.query.msg || null
    }    
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
  },
  methods: {
    goHome() {
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
.error-page {
  min-height: 100vh; 
}

.card-white {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  backdrop-filter: blur(10px);
}

.animate-bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}
</style>