<template>
  <div class="container">
    <div class="large_menu">

      <nav class="menu-bar menu-bar-local">
        <ul class="navbar-nav-small">
          <router-link to="/home" v-slot="{ href, isActive, isExactActive }" custom>
            <li class="nav-item" :class="[
              isActive && 'active router-link-active',
              isExactActive && 'router-link-exact-active',
            ]">
              <a :href="href" class="nav-link">
                <i class="fal fa-newspaper fa-2x"></i>&nbsp;
                HOT NEWS
              </a>
            </li>
          </router-link>
          <!--
          <router-link to="/search" v-slot="{ href, isActive, isExactActive }" custom>
            <li class="nav-item" :class="[
              isActive && 'active router-link-active',
              isExactActive && 'router-link-exact-active',
            ]">
              <a :href="href" class="nav-link">
                <i class="fas fa-search"></i>
                SEARCH
              </a>
            </li>
          </router-link>
          -->
          <router-link to="/news/my_portfolio" v-slot="{ href, isActive, isExactActive }" custom>
            <li class="nav-item" :class="[
              isActive && 'active router-link-active',
              isExactActive && 'router-link-exact-active',
            ]">
              <a :href="href" class="nav-link">
                <i class="fal fa-wallet fa-2x"></i>&nbsp;
                MY PORTFOLIO
              </a>
            </li>
          </router-link>

          <router-link to="/pages/ai_chat" v-slot="{ href, isActive, isExactActive }" custom>
            <li class="nav-item" :class="[
              isActive && 'active router-link-active',
              isExactActive && 'router-link-exact-active',
            ]">
              <a :href="href" class="nav-link">
                <i class="fal fa-robot fa-2x"></i>&nbsp;
                AI CHAT
              </a>
            </li>
          </router-link>

          <router-link v-if="user.is_admin" to="/channels/list" v-slot="{ href, isActive, isExactActive }" custom >
            <li class="nav-item" :class="[
              isActive && 'active router-link-active',
              isExactActive && 'router-link-exact-active',
            ]">
              <a :href="href" class="nav-link">
                <i class="fal fa-comment-alt-smile fa-2x"></i>&nbsp;
                CHANNELS
              </a>
            </li>
          </router-link>


          <div class="AI_IMPACT_HEADER " style="position:absolute; right: 32px; margin-top: 0px;">
            <small>
              <div class="logo-tothemoon noselect">
                <div class="logo-small-text"></div>
                <div class="logo-large-text">
                  Stock News
                </div>
                <div class="logo-small-life">Straight to the Point</div>
              </div>
            </small>
          </div>

        </ul>


      </nav>
      <div style="width:100%;">
        <form v-if="!isQuery" v-on:submit.prevent="perform_search">
          <div class="input-group no-border border_search">
            <fg-input v-model="query" placeholder="Search..." addon-right-icon="fal fa-search fa-lg" style="color: white !important;" @onSubmit="perform_search">

            </fg-input>
            <AutoComplete apicall="/company/suggestions" :suggestions="items" v-model:query="query"
              @update:suggestionSelected="perform_search" @suggestionsUpdated="nothing" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Navbar, NavbarToggleButton  } from "@/components";

import AutoComplete from "@/components/AutoComplete/AutoComplete.vue";

export default {
  name: 'vnud-actionbar',
  components: { AutoComplete, },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),

    isQuery() {
      const queryParams = this.$route.query;
      return queryParams.q;
    },
  },
  data() {
    return {
      items: [],
      query: "",
      href: false, isActive: false, isExactActive: false
    };
  },
  methods: {
    perform_search() {
      console.log(" SUBMIT " + this.query);      
      this.$router.push("/search?q=" + this.query);
    },
    nothing() {

    },
  }
}
</script>

<style scoped>
.large_menu {
  color: #FFFFFF;

  font-weight: 400;
  font-size: 0.8571em;

  border-width: 2px;
  border-radius: 30px;

  margin: 10px 1px;
  padding: 0px 1px;

  background-color: #87656570;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}

a {
  color: #FFFFFF;
}

a:hover {
  color: #eee;
}

.border_search {
  padding: 0px 15px;
  color: white !important;
}

.menu-bar-local {
  padding: 10px 10px;
}
</style>
